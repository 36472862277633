<template>
    <comp-table title="商城配货汇总" :columns="columns" :table-api="'/gateway/apps/o2o/api/terminal/goods/getSupplierSalesReport?date=YEAR&custGlobalId=' + custGlobalId" :watch="watch">
        <template v-slot:search="data">
            <FormItem style="margin-bottom: 0">
                <Select placeholder="选择群组" v-model="data.search.terminalGroupId" filterable style="width:200px" transfer>
                    <Option v-for="item in groups" :value="item.id" :key="item.id">{{ item.groupName }}</Option>
                </Select>
            </FormItem>
            <FormItem style="margin-bottom: 0">
                <Select placeholder="选择供应商" v-model="data.search.supplierId" filterable style="width:200px" transfer>
                    <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
                </Select>
            </FormItem>
            <FormItem style="margin-bottom: 0">
                <Select placeholder="选择终端" v-model="data.search.terminalUuid" filterable style="width:200px" transfer>
                    <Option v-for="item in terminals" :value="item.id" :key="item.id">{{ item.terminalName }}</Option>
                </Select>
            </FormItem>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            groups: null,
            suppliers: null,
            terminals: null,
            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,

            columns: [
                {
                    title: "群组",
                    key: "groupName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "供应商",
                    key: "supplierName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "商品",
                    key: "goodsName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "规格",
                    key: "goodsSpName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "库存",
                    key: "distCount",
                    minWidth: 200,
                    align: "center",
                },
            ],

            watch: {
                terminalGroupId: v => {
                    this.getSupplier(v)
                    this.getTerminal(v)
                },
            },
        }
    },

    mounted() {
        this.getGroup()
    },

    methods: {
        /**
         * 获取群组
         */
        getGroup() {
            this.$get("/gateway/apps/o2o/api/terminal/goods/getTerminalGroupOptions", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.groups = res.dataList
                }
            })
        },

        /**
         * 获取供应商
         */
        getSupplier(id) {
            if (!id) return (this.suppliers = null)

            this.$get("/gateway/apps/o2o/api/terminal/goods/listGroupSupplierByGroupId", {
                terminalGroupId: id,
            }).then(res => {
                if (res.code == 200) {
                    this.suppliers = res.dataList
                }
            })
        },

        /**
         * 获取终端
         */
        getTerminal(id) {
            if (!id) return (this.terminals = null)

            this.$get("/gateway/apps/o2o/api/terminal/goods/listTerminalByGroupId", {
                terminalGroupId: id,
            }).then(res => {
                if (res.code == 200) {
                    this.terminals = res.dataList
                }
            })
        },
    },
}
</script>
<style lang="less"></style>
